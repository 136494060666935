/* MyComponent.css */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: "Alegreya Sans", sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: 1.15;
    font-size: xx-large;
}

.hero-section {
    position: relative;
    text-align: center;
    color: white;
}

.hero-section img {
    width: 100%;
    height: auto;
}

.hero-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px;
}

.about-section, .contact-section {
    padding: 20px;
    text-align: center;
}

.about-section h2, .contact-section h2 {
    margin-bottom: 15px;
}

.my-component {
    display: flex;
    flex-direction: column; /* Align items vertically */
    justify-content: center; /* Vertically center the content */
    align-items: center; /* Horizontally center the content */
    padding: 1cm 0; /* 1cm space at the top and bottom */
    padding-left: 10%;
    padding-right: 10%;
    text-align: center; /* Center the text inside the component */
  }
  