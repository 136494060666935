.footer-banner {
  display: flex;
  justify-content: space-between;
  align-items: stretch; /* Ensure sections stretch to equal heights */
  background-color: white;
  padding: 20px;
  border-top: 9px solid lightgray;
}

.footer-section {
  flex: 1;
  text-align: left;
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
}

/* Vertical divider between sections */
.footer-section:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0; /* Ensure the line spans the full height */
  width: 2px;
  background-color: #4A8FC7; /* Blue line */
}

.footer-section p {
  margin: 10px 0;
  font-family: "Alegreya Sans", sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #555;
  line-height: 0.75;
}

.bold {
  font-family: "Alegreya Sans SC", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size:x-large;
  color: #555;
}

.small {
  font-size: smaller;
}

.social-icon {
  width: 100px;
  height: 100px;
  margin: 0 30px;
}

.social-media p {
  margin-top: 10px;
  font-size: 12px;
  color: #555;
}

@media (max-width: 768px) {
  .footer-banner {
    flex-direction: column;
  }
  .footer-section {
    margin-bottom: 20px;
  }
  .footer-section:not(:last-child)::after {
    display: none;
  }
}